<template>
	<v-flex :class="computeSize()">
		<v-layout row wrap shrink justify-start align-left>
			<LabelledCounter
				v-for="status in statuses"
				:key="status.id"
				:count="getStatusCounter(status)"
				:label="$t(status.name)"
				:color="status.color"
				:minimized="minimized"
			/>
		</v-layout>
	</v-flex>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'TasksManagerTaskCounters',
	components: {
		LabelledCounter: () => ({
			component: import('@/components/Commons/LabelledCounter')
		})
	},
	props: {
		task: {
			required: true,
			type: Object
		},
		minimized: {
			required: false,
			type: Boolean,
			default: null
		}
	},
	computed: {
		...mapState({
			statuses: state => state.tasks.statuses
		})
	},
	methods: {
		getStatusCounter: function (status) {
			return this.task.childrenStatusesCounters && this.task.childrenStatusesCounters[status.id] ? this.task.childrenStatusesCounters[status.id] : 0
		},
		computeSize: function () {
			let size
			if (this.minimized) {
				size = 'xs2'
			} else {
				size = 'xs3 md5 lg4 xl3'
			}
			return size
		}
	}
}
</script>
